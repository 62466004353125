@import "../scss/variables";
/* =========== Horizontal-color CSS ============= */

@media (min-width: 568px) and(max-width:767px) {
	.app{
		.responsive-logo .logo-2, .responsive-logo .dark-logo-2{
			display:none;
		}
		&.light-theme{
			.responsive-logo .dark-logo-1{
				display:none;
			}
		}
		&.dark-theme{
			.responsive-logo .logo-1{
				display:none;
			}
		}
	}
}

@media (max-width: 568px) {
	.app{
		.responsive-logo .logo-1, .responsive-logo .dark-logo-1{
			display:none;
		}
		&.light-theme{
			.responsive-logo .dark-logo-2{
				display:none;
			}
		}
		&.dark-theme{
			.responsive-logo .logo-2{
				display:none;
			}
		}
		.responsive-logo .logo-2, .responsive-logo .dark-logo-2{
			height: 2.5rem;
			width: 2.5rem;
		}
	}
}
@media (max-width: 767px) {
	.app{
		.sticky-pin{
			box-shadow:none !important;
		}
		&.light-theme{
			.main-header.sticky-pin{
				border-bottom:1px solid $border !important;
				background:$white;
			}
		}
		&.dark-theme{
			.main-header.sticky-pin{
				border-bottom:1px solid rgba(227, 231, 237, 0.1) !important;
				background:$dark-theme;
			}
		}
	}
}
.light-theme{
	@media (min-width:992px){
		.horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > a{
			color:#8291af !important;
		}
		.hor-menu .horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu .link-list li a{
			color:#8291af !important;
		}
	}
	&.body-style1{
		background-color:$white;
		.card{
			border:1px solid $border !important;
		}
		&.app .main-header{
			background:$white;
		}
		.main-footer{
			background:$white;
		}
		.side-menu__item.active .side-angle1{
			border-color: transparent $white transparent transparent;
		}
		.side-menu__item.active .side-angle2{
			border-color: transparent $white transparent transparent;
		}
		.side-menu__item.active .side-arrow{
			background:$white;	
		}
		@media (min-width:992px){
			.hor-menu .horizontalMenu > .horizontalMenu-list > li > a.active .side-arrow{
				background:$white;
			}
			.hor-menu .horizontalMenu > .horizontalMenu-list > li > a.active .side-angle2{
				border-color: transparent $white transparent transparent;
			}
			.hor-menu .horizontalMenu > .horizontalMenu-list > li > a.active .side-angle1{
				border-color: transparent $white transparent transparent;
			}
		}
		&.leftmenu-light{
			.app-sidebar{
				background:$background;
				.slide .side-menu__item{
					background:$background;
				}
				.app-sidefooter{
					background:$background;
				}
				.side-menu__item.active .side-angle1:after {
					background: $background;
				}
				.side-menu__item.active .side-angle2:after {
					background: $background;
				}
				.slide.is-expanded {
					background:$background;
				}
				.slide-item{
					color:$white-8;
				}
			}
		}
		&.horizontal-light{
			.horizontal-main.hor-menu{
				background: $background;
			}
			@media (min-width:992px){
				.hor-menu .horizontalMenu > .horizontalMenu-list > li > a.active .side-angle1:after {
					background: $background;
				}
				.hor-menu .horizontalMenu > .horizontalMenu-list > li > a.active .side-angle2:after {
					background: $background;
				}
				.hor-menu .horizontalMenu > .horizontalMenu-list > li > a.active{
					background: $background;
				}
			}
			@media only screen and (max-width: 991px){
				.horizontalMenu > .horizontalMenu-list{
					background:$background;
				}
				.hor-menu .horizontalMenu > .horizontalMenu-list > li > a.active{
					background: $background;
				}	
				.horizontalMenu > .horizontalMenu-list > li > ul.sub-menu{
					background: $background;
				}
				.mega-menubg{
					background: $background !important;
				}
			}
		}
	}
	&.body-default{
		background-color:$background;
		
	}	
}
body.dark-theme {
	&.body-style1{
		background-color:$dark-theme !important;
		.card{
			border:1px solid rgba(227, 231, 237, 0.1) !important;
		}
		&.app .main-header{
			background:$dark-theme;
		}
		.main-footer{
			background:$dark-theme;
		}
		.side-menu__item.active .side-angle1{
			border-color: transparent $dark-theme transparent transparent;
		}
		.side-menu__item.active .side-angle2{
			border-color: transparent $dark-theme transparent transparent;
		}
		.side-menu__item.active .side-arrow{
			background:$dark-theme !important;	
		}
		&.leftmenu-dark{
			.app-sidebar{
				background:$dark-theme3;
				.slide .side-menu__item{
					background:$dark-theme3;
				}
				.app-sidefooter{
					background:$dark-theme3;
				}
				.side-menu__item.active .side-angle1:after {
					background: $dark-theme3;
				}
				.side-menu__item.active .side-angle2:after {
					background:$dark-theme3;
				}
				.slide.is-expanded {
					background:$dark-theme3;
				}
				.slide-item{
					color:$white-8;
				}
			}
			.main-sidebar-header{
				background:$dark-theme3;
			}
		}
		@media (min-width:992px){
			.hor-menu .horizontalMenu > .horizontalMenu-list > li > a.active .side-arrow{
				background:$dark-theme;
			}
			.hor-menu .horizontalMenu > .horizontalMenu-list > li > a.active .side-angle2{
				border-color: transparent $dark-theme transparent transparent;
			}
			.hor-menu .horizontalMenu > .horizontalMenu-list > li > a.active .side-angle1{
				border-color: transparent $dark-theme transparent transparent;
			}
		}
		&.horizontal-dark{
			.horizontal-main.hor-menu{
				background: $dark-theme3;
			}
			@media (min-width:992px){
				.hor-menu .horizontalMenu > .horizontalMenu-list > li > a.active .side-angle1:after {
					background: $dark-theme3;
				}
				.hor-menu .horizontalMenu > .horizontalMenu-list > li > a.active .side-angle2:after {
					background: $dark-theme3;
				}
				.hor-menu .horizontalMenu > .horizontalMenu-list > li > a.active{
					background: $dark-theme3;
					color:$white;
				}
			}
			@media only screen and (max-width: 991px){
				.horizontalMenu > .horizontalMenu-list{
					background:$dark-theme3;
				}
				.hor-menu .horizontalMenu > .horizontalMenu-list > li > a.active{
					background: $dark-theme3;
					color:$white;
				}	
				.horizontalMenu > .horizontalMenu-list > li > ul.sub-menu{
					background: $dark-theme3;
				}
				.mega-menubg{
					background: $dark-theme3 !important;
				}
			}
		}
	}
	&.body-default{
		background-color:$dark-theme;
	}	
	&.leftmenu-dark{
		.main-sidebar-header{
			background:$dark-theme;
		}
	}
	&.leftmenu-light{
		.main-sidebar-header{
			background:$white;
		}
	}
}
.leftmenu-color{
	.main-sidebar-header{
		.logo-dark {
			display:block !important;
		}
		.logo-light{
			display:none;
		}
	}
	.app-sidebar{
		.sub-slide-item:hover{
			color:$white !important;
		}
		.sub-side-menu__item:hover{
			color:$white !important;
		}
	}
}
.leftmenu-dark{
	.main-sidebar-header{
		.logo-dark {
			display:block !important;
		}
		.logo-light{
			display:none;
		}
	}
	.app-sidebar{
		.sub-slide-item:hover{
			color:$white !important;
		}
		.sub-side-menu__item:hover{
			color:$white !important;
		}
	}
}
.leftmenu-light{
	.main-sidebar-header{
		.logo-light {
			display:block !important;
		}
		.logo-dark{
			display:none;
		}
	}
}
.reset{
	.main-sidebar-header{
		.logo-dark {
			display:block !important;
		}
		.logo-light{
			display:none;
		}
	}
}
.header-light, .reset-header{
	.horizontalMenucontainer .main-header-search .form-control {
		border: 1px solid $border !important;
		background:$white;
		color:$default-color;
		box-shadow:none !important;
	}
}
.light-theme{
	&.horizontal-light{
		.sticky-wrapper.is-sticky{
			.horizontal-main.hor-menu{
				box-shadow: 0 5px 15px 5px rgba(80, 102, 224, 0.08);		
			}
		}
	}
}
.dark-theme{
	&.horizontal-dark{
		.sticky-wrapper.is-sticky{
			.horizontal-main.hor-menu{
				box-shadow: 0px 5px 15px 5px rgba(4, 17, 56, 0.17);	
			}
		}
	}
}
.reset{
	.horizontalMenucontainer{
		.menu-header-content.bg-primary{
			background-color: $gray-100 !important;
		}
		.main-header-notification > a::after {
			border-top-color: $gray-100;
			border-left-color: $gray-100;
			background-color: $gray-100;
		}
		.main-profile-menu a.profile-user::after {
			border-top-color: $gray-100;
			border-left-color: $gray-100;
			background-color: $gray-100;
		}	
		.header-img {
			background:linear-gradient(rgba(244, 245, 253,0.8), rgba(244, 245, 253,0.95 )), url(../img/20.jpg);	
			span{
				color:$gray-800;
				opacity:0.7;
			}
			h6{
				color:$gray-800;
			}
		}
		.menu-header-title.text-white{
			color:$gray-800 !important;
		}
	}
	&.dark-theme{
		.horizontalMenucontainer{
			.menu-header-content.bg-primary{
				background-color: $dark-theme3 !important;
			}
			.main-header-notification > a::after {
				border-top-color: $dark-theme3 ;
				border-left-color: $dark-theme3 ;
				background-color: $dark-theme3 ;
			}
			.main-profile-menu a.profile-user::after {
				border-top-color: $dark-theme3 ;
				border-left-color: $dark-theme3 ;
				background-color: $dark-theme3 ;
			}	
			.header-img {
				background:linear-gradient(rgba(24, 29, 62,0.8), rgba(24, 29, 62,0.95 )), url(../img/20.jpg);	
				span{
					color:$white-9;
					opacity:0.7;
				}
				h6{
					color:$white-9;
				}
			}
		}
	}
}
.header-color{
	.menu-header-content.bg-primary{
		background-color: $gray-100 !important;
	}
	.main-header-notification > a::after {
		border-top-color: $gray-100;
		border-left-color: $gray-100;
		background-color: $gray-100;
	}
	.main-profile-menu a.profile-user::after {
		border-top-color: $gray-100;
		border-left-color: $gray-100;
		background-color: $gray-100;
	}	
	.header-img {
		background:linear-gradient(rgba(244, 245, 253,0.8), rgba(244, 245, 253,0.95 )), url(../img/20.jpg);	
		span{
			color:$gray-800;
			opacity:0.7;
		}
	}
	&.dark-theme{
		.menu-header-content.bg-primary{
			background-color: $dark-theme3 !important;
		}
		.main-header-notification > a::after {
			border-top-color: $dark-theme3 ;
			border-left-color: $dark-theme3 ;
			background-color: $dark-theme3 ;
		}
		.main-profile-menu a.profile-user::after {
			border-top-color: $dark-theme3 ;
			border-left-color: $dark-theme3 ;
			background-color: $dark-theme3 ;
		}	
		.header-img {
			background:linear-gradient(rgba(24, 29, 62,0.8), rgba(24, 29, 62,0.95 )), url(../img/20.jpg);	
			span{
				color:$white-9;
				opacity:0.7;
			}
		}
	}
}
.header-dark{
	.horizontalMenucontainer {
		.main-header {
			&.hor-header {
				border-bottom: 1px solid $white-1;
			}
		}
	}	
	.desktop-logo{
		display:none;
	}
	.desktop-logo-1{
		display:none;
	}
}
.header-color{
	.horizontalMenucontainer {
		.main-header {
			&.hor-header {
				border-bottom: 1px solid $white-1;
			}
		}
	}	
	.desktop-logo{
		display:none;
	}
	.desktop-logo-1{
		display:none;
	}
}
.header-light{
	.horizontalMenucontainer {
		.main-header {
			&.hor-header {
				border-bottom: 1px solid $border;
			}
		}
	}	
	.desktop-logo-dark{
		display:none;
	}
	.desktop-dark{
		display:none;
	}
}
.reset-header{
	&.light-theme{
		.desktop-logo-dark{
			display:none;
		}
		.desktop-dark{
			display:none;
		}
		.horizontalMenucontainer {
			.main-header {
				&.hor-header {
					border-bottom: 1px solid $border;
				}
			}
		}	
	}	
	&.dark-theme{
		.horizontalMenucontainer {
			.main-header {
				&.hor-header {
					border-bottom: 1px solid $white-1;
				}
			}
		}	
		.desktop-logo{
			display:none;
		}
		.desktop-logo-1{
			display:none;
		}
	}
}